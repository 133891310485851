import React, { useEffect, useState } from "react";
import { allGroupsApi } from "../../api/userApi/UserApi";
import Layout from "../../component/layout/layout";
import AllGroup from "../../component/table/allGroup";

function GetAllGroup() {
  const [data, setData] = useState([]);

  useEffect(() => {
    GetAllGroupApi();
  }, []);

  async function GetAllGroupApi() {
    const result = await allGroupsApi();

    setData(result);
  }

  const columns = [
    {
      Header: "Group Title",
      accessor: "groupTitle",
    },
    {
      Header: "Group Type",
      accessor: "groupType",
    },
    {
      Header: "Wallet Link",
      accessor: "walletLink",
    },
    {
      Header: "Wallet Name",
      accessor: "walletName",
    },
    {
      Header: "Token Type String",
      accessor: "tokenTypeString",
    },
    {
      Header: "No. Of Tokens",
      accessor: "noOfTokens",
    },
  ];

  return (
    <>
      <Layout>
        <div className="">
          <h3 className="text-sky-600 font-medium text-3xl">All Groups List</h3>
          <div className="mt-8">
            {data && data.length > 0 ? (
              <AllGroup columns={columns} data={data} />
            ) : (
              <h4 className="text-black font-bold text-3xl mt-8 text-center">
                No Data Available For Now.
              </h4>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GetAllGroup;
