import React, { useState, useEffect } from "react";
import {
  allGroupsApi,
  DashboardApi,
  GroupAllReferralsApi,
} from "../../api/userApi/UserApi";
import Layout from "../../component/layout/layout";
import AllReferral from "../../component/table/allReferral";

function Dashboard() {
  const columns = [
    {
      Header: "User Name",
      accessor: "userId.telegramUserName",
    },
    {
      Header: "First Name",
      accessor: "userId.telegramFirstName",
    },
    {
      Header: "Point Earned",
      accessor: "pointEarned",
    },
    {
      Header: "Total Number of Referred",
      accessor: "referralListNumber",
    },
  ];

  const [data, setData] = useState([]);

  const [group, setGroup] = useState([]);

  const [dashbaord, setDashbaord] = useState({users:0,groups:0});

  useEffect(() => {
    GetAllGroupApi();
    GetDashboardApi();
  }, []);

  async function GetAllGroupApi() {
    const result = await allGroupsApi();

    setGroup(result);
  }

  async function GetDashboardApi() {
    const result = await DashboardApi();

    setDashbaord(result);
  }

  const [groupId, setGroupId] = useState("");

  async function GetAllReferralsApi() {
    const result = await GroupAllReferralsApi(groupId);
    setData(result);
  }

  useEffect(() => {
    GetAllReferralsApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  return (
    <>
      <Layout>
        <div>
          <h3 className=" text-sky-600 font-medium text-3xl">Dashboard</h3>
          <div className=" flex flex-wrap mt-10 gap-5">
            <div className="px-10 py-3 rounded-lg shadow-lg shadow-sky-600/30">
              <h4 className="text-xl font-normal text-gray-600"> Total Number of Groups </h4>
              <h4 className="text-4xl font-semibold text-sky-600 mt-1.5">{dashbaord.groups}</h4>
            </div>
            <div className="px-10 py-3 rounded-lg shadow-lg shadow-sky-600/30">
              <h4 className="text-xl font-normal text-gray-600"> Total Number of Users </h4>
              <h4 className="text-4xl font-semibold text-sky-600 mt-1.5">{dashbaord.users}</h4>
            </div>
          </div>
          <div className="mt-10">
            <div className="flex gap-3 items-center">
              <select
                className="border border-sky-600 px-4 py-2 rounded-full focus:outline-none"
                onChange={(e) => {
                  setGroupId(e.target.value);
                }}
              >
                <option value=""> --- Select Group --- </option>
                {group.map((item, index) => (
                  <option value={item._id} key={index}>
                    {item.groupTitle}
                  </option>
                ))}
              </select>
              <h4 className="text-lg font-medium text-black"> Leader Board </h4>
            </div>
            <div className="mt-5">
              {data && data.length > 0 ? (
                <AllReferral columns={columns} data={data} />
              ) : (
                <h4 className="text-black font-bold text-3xl mt-8 text-center">
                  No Data Available For Now.
                </h4>
              )}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Dashboard;
