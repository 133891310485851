import React, { useEffect, useState } from "react";
import { loginApis } from "../../api/authApi/AuthApi";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  // Logged In user can not go to login page
  useEffect(() => {
    if (localStorage.getItem("telegramtoken")) {
      window.location.href = "/dashboard";
    }
  }, []);

  async function submitLogin(e) {
    e.preventDefault();

    const result = await loginApis({
      email: email,
      password: password,
    });

    if (result !== false) {
      localStorage.setItem("telegramtoken", result);
      window.location.href = "/dashboard";
    }

    console.log(result);
  }

  return (
    <div>
      <section className="flex justify-center items-center h-screen bg-gray-100">
        <div className="max-w-md w-full bg-white rounded p-10 space-y-4">
          <div className="mb-4">
            <p className="text-gray-600"></p>
            <h2 className="text-xl font-bold">
              Welcome to the Telegram Bot Dashboard{" "}
            </h2>
          </div>
          <div>
            <form onSubmit={submitLogin}>
              <div className="pb-4">
                <input
                  className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-black focus:bg-white focus:border-sky-600 "
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  required
                />
              </div>
              <div className="pb-4">
                <input
                  className="w-full p-4 text-sm bg-gray-50 focus:outline-none border border-gray-200 rounded text-black focus:bg-white focus:border-sky-600 "
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div>
                <button
                  className="w-full py-3 text-sky-600 hover:bg-sky-600 rounded font-bold hover:text-white border border-sky-600 transition duration-200 text-lg"
                  type="submit"
                >
                  Login
                </button>
              </div>
            </form>
          </div>

          {/* <div className="flex items-center justify-between">
            <div className="flex flex-row items-center">
              <input
                type="checkbox"
                className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
              />
              <label
                for="comments"
                className="ml-2 text-sm font-normal text-gray-600"
              >
                Remember me
              </label>
            </div>
            <div>
              <a className="text-sm text-blue-600 hover:underline" href="#">
                Forgot password?
              </a>
            </div>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default Login;
