import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Gift, Group, User, Logout } from "../icons/icons";
import "./sidebar.css";
import logo from "../../assets/telegram_logo.jpg";

function Sidebar() {
  function LogoutFunction() {
    localStorage.clear();
    window.location.href = "/";
  }

  let navigate = useNavigate();

  const [active, setActive] = useState("");

  useEffect(() => {
    if (window.location.pathname.split("/")[1] === "dashboard") {
      setActive("dashboard");
    } else if (
      window.location.pathname.split("/")[1] === "all-group" ||
      window.location.pathname.split("/")[1] === "group-users"
    ) {
      setActive("group");
    } else if (
      window.location.pathname.split("/")[1] === "all-users" ||
      window.location.pathname.split("/")[1] === "user-referral"
    ) {
      setActive("user");
    } else if (window.location.pathname.split("/")[1] === "all-referrals") {
      setActive("referral");
    } else {
      setActive("");
    }
  }, []);

  return (
    <>
      <div className="flex flex-col justify-between h-full gap-3 text-white">
        <div className="">
          <div className="">
            <img src={logo} alt="" className="mx-auto h-20 w-20" />
            <h3 className="text-2xl font-bold text-center"> Bot Dashboard</h3>
          </div>{" "}
          <div
            className={
              active === "dashboard"
                ? "mt-16 flex gap-2 text-lg cursor-pointer px-2 py-1.5 bg-white text-sky-600 rounded-lg font-semibold"
                : "mt-16 flex gap-2 text-lg cursor-pointer px-2 py-1.5 font-normal"
            }
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            <div className="my-auto">
              <User />
            </div>
            <h4 className="">Dashboard</h4>
          </div>
          <div
            className={
              active === "user"
                ? "mt-2 flex gap-2 text-lg cursor-pointer px-2 py-1.5 bg-white text-sky-600 rounded-lg font-semibold"
                : "mt-2 flex gap-2 text-lg cursor-pointer px-2 py-1.5 font-normal"
            }
            onClick={() => {
              navigate("/all-users");
            }}
          >
            <div className="my-auto">
              <User />
            </div>
            <h4 className="">All User</h4>
          </div>
          <div
            className={
              active === "group"
                ? "mt-2 flex gap-2 text-lg cursor-pointer px-2 py-1.5 bg-white text-sky-600 rounded-lg font-semibold"
                : "mt-2 flex gap-2 text-lg cursor-pointer px-2 py-1.5 font-normal"
            }
            onClick={() => {
              navigate("/all-group");
            }}
          >
            <div className="my-auto">
              <Group />
            </div>
            <h4 className="">All Group</h4>
          </div>
          <div
            className={
              active === "referral"
                ? "mt-2  gap-2 text-lg cursor-pointer px-2 py-1.5 bg-white text-sky-600 rounded-lg font-semibold hidden"
                : "mt-2  gap-2 text-lg cursor-pointer px-2 py-1.5 font-normal hidden"
            }
            onClick={() => {
              navigate("/all-referrals");
            }}
          >
            <div className="my-auto">
              <Gift />
            </div>
            <h4 className="">All Referrals</h4>
          </div>
        </div>
        <div className="">
          <div
            className="flex gap-2 text-xl font-semibold cursor-pointer"
            onClick={() => {
              LogoutFunction();
            }}
          >
            <div className="my-auto">
              <Logout />
            </div>
            <h4 className="">Logout</h4>
          </div>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
