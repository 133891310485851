import axios from "axios";

/** all groups api function start */

export const allGroupsApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/get-all-groups", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      return res.data.data.response;
    })
    .catch((err) => {
      return [];
    });

  return result;
};

/** all groups api function end */

/** all user api function start */

export const allUserApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/get-users", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      return res.data.data.response;
    })
    .catch((err) => {
      return [];
    });

  return result;
};

/** all user api function end */

/** all user referrals function start */

export const allReferralsApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/all-users-referrals", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      return res.data.data.response;
    })
    .catch((err) => {
      return [];
    });

  return result;
};

/** all user referrals function end */

/** all group user function start */

export const GroupUserApi = async (id) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/get-users-in-group/" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      return res.data.data.response;
    })
    .catch((err) => {
      return [];
    });

  return result;
};

/** all group user function end */

/** all group referrals function start */

export const GroupReferralsApi = async (id) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/top20-referral/" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      return res.data.data.response;
    })
    .catch((err) => {
      return [];
    });

  return result;
};

/** all group referrals function end */

/** all group referrals function start */

export const UserReferralsApi = async (id) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/get-users-referral/" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      return res.data.data.response.referralList;
    })
    .catch((err) => {
      return [];
    });

  return result;
};

/** all group referrals function end */

/** all group referrals function start */

export const GroupAllReferralsApi = async (id) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/all-referral/" + id, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      return res.data.data.response;
    })
    .catch((err) => {
      return [];
    });

  return result;
};

/** all group referrals function end */

/** all group referrals function start */

export const DashboardApi = async () => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "/api/total-groups", {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("telegramtoken"),
      },
    })
    .then((res) => {
      console.log(res);
      return res.data.data;
    })
    .catch((err) => {
      return {users:0,groups:0};
    });

  return result;
};

/** all group referrals function end */
