import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "../pages/auth/Login";
import Dashboard from "../pages/dashboard/dashboard";
import GetAllGroup from "../pages/group/GetAllGroup";
import GetGroupUser from "../pages/group/GetGroupUser";
import GetAllReferrals from "../pages/referral/GetAllReferrals";
import GetUserReferrals from "../pages/referral/GetUserReferrals";
import GetAllUsers from "../pages/user/GetAllUsers";

function PublicRoute() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/all-group" element={<GetAllGroup />} />
          <Route path="/group-users/:id" element={<GetGroupUser />} />
          <Route path="/all-users" element={<GetAllUsers />} />
          <Route path="/all-referrals" element={<GetAllReferrals />} />
          <Route path="/user-referral/:id" element={<GetUserReferrals />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default PublicRoute;
