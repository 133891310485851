import React, { useEffect, useState } from "react";
import { allUserApi } from "../../api/userApi/UserApi";
import Layout from "../../component/layout/layout";
import AllUser from "../../component/table/allUser";

function GetAllUsers() {
  async function GetAllUserApi() {
    const result = await allUserApi();
    setData(result);
  }

  useEffect(() => {
    GetAllUserApi();
  }, []);

  const columns = [
    {
      Header: "User Name",
      accessor: "telegramUserName",
    },
    {
      Header: "First Name",
      accessor: "telegramFirstName",
    },
    {
      Header: "Join Time",
      accessor: "joinTime",
    },
    {
      Header: "Is Referred",
      accessor: "isReferred",
    },
    {
      Header: "Verified",
      accessor: "verified",
    },
  ];

  const [data, setData] = useState([]);

  return (
    <>
      <Layout>
        <div>
          <h3 className=" text-sky-600 font-medium text-3xl">All Users List</h3>
          <div className="mt-8">
            {data && data.length > 0 ? (
              <AllUser columns={columns} data={data} />
            ) : (
              <h4 className="text-black font-bold text-3xl mt-8 text-center">
                No Data Available For Now.
              </h4>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GetAllUsers;
