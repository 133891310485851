import axios from "axios";

export const loginApis = async (data) => {
  const result = await axios
    .post(process.env.REACT_APP_API_URL + "/api/login", data)
    .then((res) => {
      return res.data.data.response.accessToken;
    })
    .catch((err) => {
      return false;
    });

  return result;
};

export const accessTokenApis = async (token) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "isAccessValid", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return false;
    });

  return result;
};

export const refreshTokenApis = async (token) => {
  const result = await axios
    .get(process.env.REACT_APP_API_URL + "refreshAccessToken", {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return false;
    });

  return result;
};
