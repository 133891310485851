import React from "react";
import Sidebar from "../sidebar/sidebar";
import "./layout.css";

function Layout(props) {
  return (
    <>
      <div className="flex">
        <div className="flex-none sidebar-container py-7 px-5 bg-sky-600">
          <Sidebar />
        </div>
        <div className="py-7 px-5 w-full body-container">{props.children}</div>
      </div>
    </>
  );
}

export default Layout;
