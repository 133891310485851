import React, { useEffect, useState } from "react";
import { UserReferralsApi } from "../../api/userApi/UserApi";
import Layout from "../../component/layout/layout";
import UserReferral from "../../component/table/userReferral";

function GetUserReferrals() {
  async function UserReferralfunction(id) {
    const result = await UserReferralsApi(id);
    setData(result);
  }

  useEffect(() => {
    UserReferralfunction(window.location.pathname.split("/")[2]);
  }, []);

  const columns = [
    {
      Header: "User Name",
      accessor: "userName",
    },
    {
      Header: "First Name",
      accessor: "firstName",
    },
  ];

  const [data, setData] = useState([]);

  return (
    <>
      <Layout>
        <div className="">
          <h3 className="text-sky-600 font-medium text-3xl">
            User's Referral Details
          </h3>
          <div className="mt-8">
            {data && data.length > 0 ? (
              <UserReferral columns={columns} data={data} />
            ) : (
              <h4 className="text-black font-bold text-3xl mt-8 text-center">
                No Data Available For Now.
              </h4>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GetUserReferrals;
