/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  GroupAllReferralsApi,
  GroupReferralsApi,
  GroupUserApi,
} from "../../api/userApi/UserApi";
import Layout from "../../component/layout/layout";
import AllReferral from "../../component/table/allReferral";
import AllUser from "../../component/table/allUser";

function GetGroupUser() {
  const [userData, setUserData] = useState([]);
  const [referralData, setReferralData] = useState([]);
  const [allReferralData, setAllReferralData] = useState([]);

  useEffect(() => {
    GetGroupUserApi(window.location.pathname.split("/")[2]);
    GetGroupReferralApi(window.location.pathname.split("/")[2]);
    GetGroupAllReferralApi(window.location.pathname.split("/")[2]);
  }, []);

  async function GetGroupUserApi(id) {
    const result = await GroupUserApi(id);
    setUserData(result);
  }

  async function GetGroupReferralApi(id) {
    const result = await GroupReferralsApi(id);
    setReferralData(result);
  }

  async function GetGroupAllReferralApi(id) {
    const result = await GroupAllReferralsApi(id);
    setAllReferralData(result);
  }

  const [tab, setTab] = useState("referral");

  const userColumns = [
    {
      Header: "User Name",
      accessor: "telegramUserName",
    },
    {
      Header: "First Name",
      accessor: "telegramFirstName",
    },
    {
      Header: "Join Time",
      accessor: "joinTime",
    },
    {
      Header: "Is Referred",
      accessor: "isReferred",
    },
    {
      Header: "Verified",
      accessor: "verified",
    },
  ];

  const referralColumns = [
    {
      Header: "User Name",
      accessor: "userId.telegramUserName",
    },
    {
      Header: "First Name",
      accessor: "userId.telegramFirstName",
    },
    {
      Header: "Point Earned",
      accessor: "pointEarned",
    },
    {
      Header: "Total Number of Referred",
      accessor: "referralListNumber",
    },
  ];

  return (
    <>
      <Layout>
        <div className="">
          <h3 className="text-sky-600 font-medium text-3xl">Group's Details</h3>

          <div className="mt-9 flex gap-4">
            <h3
              className={
                tab === "referral"
                  ? "cursor-pointer text-lg font-semibold border-b-2 border-sky-600 text-sky-600"
                  : "cursor-pointer text-lg  font-normal text-black"
              }
              onClick={() => {
                setTab("referral");
              }}
            >
              Top 20 Referrals{" "}
            </h3>
            <h3
              className={
                tab === "user"
                  ? "cursor-pointer text-lg font-semibold border-b-2 border-sky-600 text-sky-600"
                  : "cursor-pointer text-lg  font-normal text-black"
              }
              onClick={() => {
                setTab("user");
              }}
            >
              All Users in Group{" "}
            </h3>{" "}
            <h3
              className={
                tab === "allref"
                  ? "cursor-pointer text-lg font-semibold border-b-2 border-sky-600 text-sky-600"
                  : "cursor-pointer text-lg  font-normal text-black"
              }
              onClick={() => {
                setTab("allref");
              }}
            >
              All Referrals
            </h3>
          </div>
          <div className="mt-8">
            {tab === "user" ? (
              userData && userData.length > 0 ? (
                <AllUser columns={userColumns} data={userData} />
              ) : (
                <h4 className="text-black font-bold text-3xl mt-8 text-center">
                  No Data Available For Now.
                </h4>
              )
            ) : tab === "referral" ? (
              referralData && referralData.length > 0 ? (
                <AllReferral columns={referralColumns} data={referralData} />
              ) : (
                <h4 className="text-black font-bold text-3xl mt-8 text-center">
                  No Data Available For Now.
                </h4>
              )
            ) : tab === "allref" ? (
              referralData && referralData.length > 0 ? (
                <AllReferral columns={referralColumns} data={allReferralData} />
              ) : (
                <h4 className="text-black font-bold text-3xl mt-8 text-center">
                  No Data Available For Now.
                </h4>
              )
            ) : (
              <></>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GetGroupUser;
