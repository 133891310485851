import React, { useEffect, useState } from "react";
import { allReferralsApi } from "../../api/userApi/UserApi";
import Layout from "../../component/layout/layout";
import AllReferral from "../../component/table/allReferral";

function GetAllReferrals() {
  async function GetAllReferralsApi() {
    const result = await allReferralsApi();
    setData(result);
  }

  useEffect(() => {
    GetAllReferralsApi();
  }, []);

  const columns = [
    {
      Header: "User Name",
      accessor: "userId.telegramUserName",
    },
    {
      Header: "First Name",
      accessor: "userId.telegramFirstName",
    },
    {
      Header: "Point Earned",
      accessor: "pointEarned",
    },
    {
      Header: "Total Number of Referred",
      accessor: "referralListNumber",
    },
  ];

  const [data, setData] = useState([]);

  return (
    <>
      <Layout>
        <div>
          <h3 className="text-sky-600 font-medium text-3xl">
            All Referred List
          </h3>
          <div className="mt-8">
            {data && data.length > 0 ? (
              <AllReferral columns={columns} data={data} />
            ) : (
              <h4 className="text-black font-bold text-3xl mt-8 text-center">
                No Data Available For Now.
              </h4>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default GetAllReferrals;
